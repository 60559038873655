import React from "react";
import Features from "./Features";
const AboutUsSection = () => {
  return (
    <div id="about">
      <Features />
    </div>
  );
}

export default AboutUsSection;
