import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
// import Placeholder from "../components/Placeholder"
import Heading from "../components/heading"
import AboutUsSection from "../components/About"
// import ContactSection from '../components/Contact'
import Footer from "../components/footer"
const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="U Gen - Evolutionary, personalised healthcare."
        description="Evolutionary, personalised healthcare."
        meta={{
          "og:title": "U Gen - Evolutionary, personalised healthcare.",
          "og:description": "Your own health simplified.",
          "og:image": "https://d35uf3gxhmj7j9.cloudfront.net/public/config/u-gen-placeholder.png"
        }}
      />
      {/* <Placeholder /> */}
      <Heading />
      <AboutUsSection />
      {/* <How /> */}
      {/* <Pricing /> */}
      {/* <Screenshots/>
      <Testimonials />
  <FAQ></FAQ>*/}
      {/* <ContactSection />  */}
      <Footer />
    </Layout>
  )
}

export default IndexPage
