import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import buttonImage from "../assets/images/google-play-badge.png"
import LogoImage from "../assets/images/white_logo.png"

export default function heading() {
  const data = useStaticQuery(headQuery)

  return (
    <header id="header" className="header">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6 offset-lg-6">
            {/* <div className="spacer"></div> */}
            <img className="logo-image ml-4" src={LogoImage} />
            <div className="text-container">
              <h1 className="h1-large">
                {data.allContentfulHeaderSection.edges[0].node.title}
                <span>
                  {data.allContentfulHeaderSection.edges[0].node.shortTitle}
                </span>
              </h1>
              <p className="p-large">
                Revolutionize your health and wellness with a personalized
                approach
              </p>
              <div className="img-container">
                <a
                  href="https://apps.apple.com/za/app/u-gen/id6444593649?itsct=apps_box_badge&amp;itscg=30200"
                  className="apple-button"
                >
                  <img
                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1674518400"
                    alt="Download on the App Store"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=io.u_gen.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  className="google-play-button pl-2"
                >
                  <img alt="Get it on Google Play" src={buttonImage} />
                </a>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-6">
            <div className="image-container">
              <img
                className="img-fluid"
                src={data.allContentfulHeaderSection.edges[0].node.image.url}
                alt="alternative"
              />
            </div>
          </div> */}
        </div>
      </div>
    </header>
  )
}
export const headQuery = graphql`
  query headerQuery {
    allContentfulHeaderSection {
      edges {
        node {
          image {
            url
          }
          shortTitle
          title
          description {
            description
          }
        }
      }
    }
  }
`
