import React from "react"
import { graphql, useStaticQuery } from "gatsby"

function Features() {
  const data = useStaticQuery(FeaturePageQuery)

  return (
    <div id="features" className="tabs">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* <h2 className="h2-heading">
              {
                data.allContentfulAboutItem.edges[0].node.description
                  .description
              }
            </h2> */}
          </div>
        </div>
        <div className="row">
          <div className="tab-content" id="templateTabsContent">
            <div
              className="tab-pane fade show active"
              id="tab-1"
              role="tabpanel"
              aria-labelledby="tab-1"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <ul className="list-unstyled li-space-lg first">
                      <li className="media">
                        <span className="fa-stack">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fas fa-info fa-stack-1x"></i>
                        </span>
                        <div className="media-body">
                          <h5>About</h5>
                          <p>
                            We are determined to revolutionize the future of
                            health and wellness by offering a personalized
                            approach through our team of health professionals
                            and Artificial Intelligence (AI).
                          </p>
                        </div>
                      </li>
                      <li className="media">
                        <span className="fa-stack">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fas fa-capsules fa-stack-1x"></i>
                        </span>
                        <div className="media-body">
                          <h5> Your Ů PILL</h5>
                          <p>
                            Our capsules are customised directly from the
                            molecular level, using natural and organic
                            ingredients. Our goal is to provide you with a
                            holistic health solution that fuels your body and
                            supports you to live your life to the fullest.
                          </p>
                        </div>
                      </li>
                      {/* <li className="media">
                        <span className="fa-stack">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="far fa-gem fa-stack-1x"></i>
                        </span>
                        <div className="media-body">
                          <h5>
                            {" "}
                            {data.allContentfulAboutItem.edges[2].node.title}
                          </h5>
                          <p>
                            {
                              data.allContentfulAboutItem.edges[2].node
                                .description.description
                            }
                          </p>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                  <div className="col-lg-4">
                    {/* <img
                      className="img-fluid"
                      src={data.allContentfulAboutItem.edges[0].node.icon.url}
                      alt="alternative"
                    /> */}
                  </div>
                  <div className="col-lg-4">
                    <ul className="list-unstyled li-space-lg">
                      <li className="media">
                        <span className="fa-stack">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fas fa-file-medical fa-stack-1x"></i>
                        </span>
                        <div className="media-body">
                          <h5> Health Screening</h5>
                          <p>
                            Following our quick in-app health screening, our
                            assigned healthcare professionals will approve your
                            Ů PILL formula and start with the process.
                          </p>
                        </div>
                      </li>
                      <li className="media">
                        <span className="fa-stack">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fas fa-truck fa-stack-1x"></i>
                        </span>
                        <div className="media-body">
                          <h5> Door-to-Door Delivery</h5>
                          <p>
                            We make it easier for you by delivering your Ů PILL
                            right to your door, complete with instructions for
                            daily use.
                          </p>
                        </div>
                      </li>
                      {/* <li className="media">
                        <span className="fa-stack">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fas fa-cube fa-stack-1x"></i>
                        </span>
                        <div className="media-body">
                          <h5>
                            {" "}
                            {data.allContentfulAboutItem.edges[5].node.title}
                          </h5>
                          <p>
                            {
                              data.allContentfulAboutItem.edges[5].node
                                .description.description
                            }
                          </p>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
export const FeaturePageQuery = graphql`
  query MyQuery {
    allContentfulAboutItem {
      edges {
        node {
          title
          order
          description {
            description
          }
          icon {
            url
          }
        }
      }
    }
  }
`
